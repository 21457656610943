import * as React from "react";
import { NavLink } from "react-router-dom";
import { Form } from "react-bootstrap";
import "../../assets/scss/style.scss";
import Swal from 'sweetalert2';
import withReactContent from "sweetalert2-react-content";
//import Breadcrumb from '../../../App/layout/AdminLayout/Breadcrumb';
//import logoDark from "../../assets/images/logo-dark.png";
import logoDark from "../../assets/images/logo2.png";

import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from '../../store/reducer';
import { login } from "../../action/userActions";



const SignUp1 = ({location, history}) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const dispatch = useDispatch()
  const redirect = location.search ? location.search.split('=')[1] : '/dashboard/default'
  //console.log(redirect)

  const userLogin = useSelector(state => state.userLogin)
  const {error, userInfo} = userLogin


  useEffect(() => {
    if (userInfo) {
        history.push(redirect)
    } 

    const sweetAlertHandler = () => {
        const MySwal = withReactContent(Swal);
        MySwal.fire({
          title: error,
          text: 'Invalid Usename or Password',
          icon: 'error',
        })
      }

    if (error) {
        sweetAlertHandler()
     }
}, [history, userInfo, error, redirect])


  const submitHandler = (e) => {
    e.preventDefault();
    //console.log('Submitted')
    dispatch(login(email, password))

    setEmail('');
    setPassword('');
  }

  //  const sweetAlertHandler = () => {
  //  const MySwal = withReactContent(Swal);
  //  MySwal.fire({
   //   title: error,
   //   text: 'Invalid Usename or Password',
   //   icon: 'error',
   // })
  //}
 
  return (
    <>
      
      <div className="auth-wrapper">
        <div className="auth-content">
          
          <div className="card">
            <div className="row align-items-center text-center">
              <div className="col-md-12">
              
                <div className="card-body">
                  <Form onSubmit={submitHandler}>
                    {
                      <img
                    
                        src={logoDark}
                        alt=""
                        className="img-fluid mb-4"
                      />
                    }
                    <h4 className="mb-3 f-w-400">Signin</h4>
                    <div className="form-group fill">
                      <input
                        type="email"
                        required
                        className="form-control"
                        placeholder="Email Address"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>
                    <div className="form-group fill mb-4">
                      <input
                        type="password"
                        required
                        className="form-control"
                        placeholder="Password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                    </div>

                    <button className="btn btn-block btn-primary mb-4">
                      Signin
                    </button>
                    <p className="mb-2 text-muted">
                      Forgot password?{" "}
                      <NavLink to="/auth/reset-password-1" className="f-w-400">
                        Reset
                      </NavLink>
                    </p>
                    <p className="mb-0 text-muted">
                      Don’t have an account?{" "}
                      <NavLink to="/auth/signup-1" className="f-w-400">
                        Signup
                      </NavLink>
                    </p>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default SignUp1;





