
//const SignIn = React.lazy(() => import('./Pages/Authentication/signin'));
//const DashboardDefault = React.lazy(() => import('./Pages/Dashboard/Default'));
import testpage from './Pages/Authentication/signin'
//import DashboardDefault from './Pages/Dashboard/Default'
import testpage1 from './Pages/Dashboard/test'
const route = [
    { path: '/', exact: true, name: 'Dashboard', component: testpage1 },
    { path: '/login', exact: true, name: 'Login', component: testpage },
    //{ path: '/dashboard/default', exact: true, name: 'Analytics', component: DashboardDefault },
    
];
export default route;