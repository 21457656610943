import { combineReducers } from 'redux';
import { createSelectorHook } from 'react-redux';
import ableReducer from './ableReducer'
import demoReducer from './demoReducer';
import { userLoginReducer, } from '../reducers/userReducers'
const reducer = combineReducers({
    able: ableReducer,
    demo: demoReducer,
    userLogin: userLoginReducer
});




export const useSelector = createSelectorHook();
export default reducer;

