import {
    USER_LOGIN_REQUEST,
    USER_LOGIN_SUCCESS,
    USER_LOGIN_FAIL,

    USER_LOGOUT,

} from '../constants/userConstants'

const userInfoFromStorage = localStorage.getItem('userInfo') ?
    JSON.parse(localStorage.getItem('userInfo')) : null



const initialState = {userInfo: userInfoFromStorage};


export const userLoginReducer = (state = initialState, action) => {
    console.log("Reducer: ", state);
    switch (action.type) {
      
        case USER_LOGIN_REQUEST:
            return { loading: true }

        case USER_LOGIN_SUCCESS:
            return { ...state, loading: false, userInfo: action.payload }

        case USER_LOGIN_FAIL:
            return { ...state, loading: false, error: action.payload }

        case USER_LOGOUT:
            return {}

        default:
            return state
    }
}