import * as React from 'react';
//import TicketDetails from './Pages/Sales/TicketDetails';
//import Cart from './Pages/Sales/Cart';
const DashboardDefault = React.lazy(() => import('./Pages/Dashboard/Sales'));
const Product = React.lazy(() => import('./Pages/Sales/Product'));
const Orders = React.lazy(() => import('./Pages/Sales/Order'));
const Resellers = React.lazy(() => import('./Pages/Sales/Sellers'));
const Checkout= React.lazy(() => import('./Pages/Sales/Checkout'));
const Cart= React.lazy(() => import('./Pages/Sales/Cart'));
const ProductDetails= React.lazy(() => import('./Pages/Sales/ProductDetails'));
const TicketDetails= React.lazy(() => import('./Pages/Sales/TicketDetails'));
//import DashboardDefault from './Pages/Dashboard/Default'

const routes = [
    { path: '/dashboard/default', exact: true, name: 'default', component: DashboardDefault },
    { path: '/products', exact: true, name: 'product', component: Product },
    { path: '/purchase-order', exact: true, name: 'orders', component: Orders },
    { path: '/reseller', exact: true, name: 'reseller', component: Resellers },
    { path: '/checkout', exact: true, name: 'checkout', component: Checkout },
    { path: '/productdetails', exact: true, name: 'productdetails', component: ProductDetails },
    { path: '/cart', exact: true, name: 'productdetails', component: Cart },
    { path: '/purchaseorder', exact: true, name: 'purchase-order', component: TicketDetails },
];
export default routes;